import React, { useContext, useEffect, useState } from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { Flex, Text } from "rebass"
import Collapsible from "react-collapsible"

import Vogue from "../../assets/images/Vogue.svg"
import WSJ from "../../assets/images/WSJ.svg"
import Glossier from "../../assets/images/Glossier.svg"
import Elle from "../../assets/images/Elle.svg"
import AnOther from "../../assets/images/another-image.png"
import AD from "../../assets/images/AD.svg"
import useWindowDimensions from "../../utils/use-window-dimensions"
import StoreContext from "../../context/store-context"
import { navigate } from "gatsby"

const Container = styled(Flex)`
  width: 100%;

  padding-left: 35px;
  padding-right: 35px;

  align-items: center;

  ${(props) => props.theme.bp.desktop} {
    flex-direction: row;
    min-height: 250px;
  }

  min-height: 300px;

  margin-top: 50px;
  margin-bottom: 50px;

  flex-direction: column-reverse;
`

const InfoContainer = styled(Flex)`
  flex-direction: column;

  font-size: 11px;
  font-family: Georgia;

  ${(props) => props.theme.bp.desktop} {
    width: 15%;
    align-items: flex-start;
  }

  align-items: center;

  width: 100%;

  > div {
    cursor: pointer;
  }
`

const FeatureContainer = styled(Flex)`
  height: 75%;
  width: 100%;

  flex-wrap: wrap;
  justify-content: center;

  .div {
    max-height: 50px;
    max-width: 150px;
  }

  > img {
    width: 100%;
    object-fit: contain;
  }
`

const FeatureItem = styled(Flex)`
  flex-basis: 50%;
  justify-content: center;
  height: 100px;

  ${(props) =>
    props.isAnother &&
    `
    transform: scale(3);
  `}

  ${(props) => props.theme.bp.mobile} {
    flex-basis: 50%;
    justify-content: center;
    height: 30px;
    margin-top: 30px;
  }

  ${(props) => props.theme.bp.tablet} {
    flex-basis: 50%;
    justify-content: center;
    height: 40px;
    margin-top: 30px;
  }

  ${(props) => props.theme.bp.desktop} {
    flex-basis: 33%;
    justify-content: center;
    margin-top: 30px;
    height: 35px;
  }

  ${(props) => props.theme.bp.largeDesktop} {
    flex-basis: 16%;
    justify-content: center;
    height: 35px;
  }
`

const features = [Vogue, WSJ, Glossier, Elle, AnOther, AD]

const Footer = ({}) => {
  const [pickerOpen, setPickerOpen] = useState(false)
  const [settingRegion, setSettingRegion] = useState(false)

  const { regions, cart, setRegion } = useContext(StoreContext)

  const { width } = useWindowDimensions()

  const handleSetRegion = async (regionId, countryCode) => {
    setSettingRegion(true)
    try {
      await setRegion(regionId, countryCode)
      setSettingRegion(false)
    } catch (erroe) {
      setSettingRegion(false)
    }
    setPickerOpen(false)
  }

  const handleInstagramClick = () => {
    if (window) {
      window.open("https://www.instagram.com/linesander_", "_blank")
    }
  }

  return (
    <Container width="100%">
      <InfoContainer mt={5}>
        {cart && cart.region && (
          <Flex flexDirection="row">
            <Collapsible
              open={pickerOpen}
              transitionTime={100}
              trigger={
                <Text
                  onClick={() => setPickerOpen(true)}
                  mb="10px"
                  textAlign={width <= 768 ? "center" : "left"}
                >
                  {`${cart.region.name.toUpperCase()} / ${cart.currencyCode.toUpperCase()}`}
                </Text>
              }
            >
              <Flex flexDirection="column" alignItems="center">
                {regions &&
                  regions
                    .filter((region) => region.id !== cart.region.id)
                    .map((region, i) => (
                      <Text
                        key={i}
                        width="100%"
                        textAlign={width > 768 ? "left" : "center"}
                        onClick={() =>
                          handleSetRegion(region.id, region.country_code)
                        }
                        mb="10px"
                      >
                        {`${region.name.toUpperCase()} / ${region.currency_code.toUpperCase()}`}
                      </Text>
                    ))}
              </Flex>
            </Collapsible>
          </Flex>
        )}
        <Text mb="10px">LSJ STUDIO ©</Text>
        <Text mb="10px" onClick={() => handleInstagramClick()}>
          INSTAGRAM
        </Text>
        <Text mb="10px" onClick={() => navigate(`/terms`)}>
          TERMS
        </Text>
      </InfoContainer>
      <FeatureContainer>
        {features.map((feat, i) => (
          <FeatureItem key={i} isAnother={i === 4}>
            <img src={feat} />
          </FeatureItem>
        ))}
      </FeatureContainer>
    </Container>
  )
}

export default Footer
