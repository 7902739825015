import styled from "@emotion/styled"
import { Input } from "@rebass/forms"
import { navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Box, Button, Flex, Image, Text } from "rebass"
import MenuCross from "../../assets/images/cross.svg"
import {
  formatCartDiscount,
  formatCartSubtotal,
  formatCartTotal,
  formatPrices,
  formatVatDeduction,
} from "../../utils/prices"
import useWindowDimensions from "../../utils/use-window-dimensions"

const CartDrawer = styled(Flex)`
  position: fixed;
  flex-direction: column;

  font-family: Georgia;
  font-style: normal;
  font-weight: normal;

  ${(props) => props.theme.bp.mobile} {
    width: 100%;
  }

  ${(props) => props.theme.bp.tablet} {
    width: 100%;
  }

  ${(props) => props.theme.bp.desktop} {
    width: 50%;
  }

  ${(props) => props.theme.bp.largeDesktop} {
    width: 40%;
  }

  height: 100%;

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1rem;

  bottom: 0;
  right: 0;

  background-color: #eeebeb;

  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};

  transition: visibility 0.2s, opacity 0.2s linear;

  box-shadow: -19px 0 99px 0 rgba(0, 0, 0, 0.07),
    -10px 0 50px 0 rgba(0, 0, 0, 0.1);

  z-index: 1001;
`

const ItemsContainer = styled(Flex)`
  margin-top: 15%;
  flex-direction: column;
  height: 100%;

  ${(props) => props.theme.bp.mobile} {
    margin-top: 25%;
  }
`

const CartItem = styled(Flex)`
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 20px;

  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  max-height: 120px;

  justify-content: space-evenly;
`

const EmptyCart = styled(Flex)`
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  text-align: center;

  font-family: Georgia;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`

const RemoveIcon = styled.div`
  cursor: pointer;
  display: inline-block;
`

const StyledButton = styled(Button)`
  background-color: #eeebeb;
  color: #00003a;
  outline: none;
  display: inline-block;
  width: fit-content;
  text-align: center;

  font-family: Georgia;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const StyledInput = styled(Input)`
  border-bottom: 1px solid #00003a !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;

  border-radius: 0px !important;

  width: 175px;

  &:focus {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline: none;
  }
`

const CartDrawerContainer = ({
  visible,
  setVisibility,
  cart,
  onRemoveLine,
  onQuantityUpdate,
  addCouponCode,
  removeCouponCode,
}) => {
  const cartRef = useRef()

  const [couponCode, setCouponCode] = useState("")
  const [showPromo, setShowPromo] = useState(false)

  const { width } = useWindowDimensions()

  let inputRef = null

  const handleClick = (e) => {
    if (cartRef.current && !cartRef.current.contains(e.target)) {
      // inside click
      setVisibility()
    }
  }

  useEffect(() => {
    // add when mounted
    if (visible) {
      document.addEventListener("mouseover", handleClick, true)
    }
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseover", handleClick, true)
    }
  }, [visible])

  return (
    <CartDrawer visible={visible} ref={cartRef} isMobile={width <= 425}>
      {width <= "768" && (
        <Image
          onClick={() => setVisibility()}
          src={MenuCross}
          sx={{
            left: "20px",
            position: "fixed",
            top: "20px",
            cursor: "pointer",
          }}
        />
      )}
      {cart.items.length ? (
        <ItemsContainer>
          {cart.items.map((item, i) => {
            const { variant } = item

            const price = formatPrices(cart, item.unit_price)

            return (
              <CartItem key={i}>
                <Image
                  src={variant.product.thumbnail}
                  width="85px"
                  height="100px"
                />
                <Flex flexDirection="column" maxWidth="150px">
                  <Text>{variant.product.title}</Text>
                  <Text>x {item.quantity}</Text>
                  <Text>{price}</Text>
                </Flex>
                <RemoveIcon onClick={() => onRemoveLine(item.id)}>
                  &#215;
                </RemoveIcon>
              </CartItem>
            )
          })}
          <Box mt="auto" />
          <Flex width="100%" mb={1}>
            <Text>Subtotal</Text>
            <Box ml="auto" />
            <Text>{formatCartSubtotal(cart)} </Text>
          </Flex>
          {!!cart.discountTotal && (
            <Flex width="100%" mb={1}>
              <Text mr={2}>Discount: {cart.discounts[0].code}</Text>
              <RemoveIcon
                onClick={() => removeCouponCode(cart.discounts[0].code)}
              >
                &#215;
              </RemoveIcon>
              <Box ml="auto" />
              <Text>{formatCartDiscount(cart)}</Text>
            </Flex>
          )}
          {cart?.discounts?.[0]?.rule?.type === "free_shipping" && (
            <Flex width="100%" mb={1}>
              <Text mr={2}>Free shipping</Text>
              <RemoveIcon
                onClick={() => removeCouponCode(cart.discounts[0].code)}
              >
                &#215;
              </RemoveIcon>
            </Flex>
          )}
          <Flex width="100%" my={1}>
            <Text>Total</Text>
            <Box ml="auto" />
            <Text>{formatCartTotal(cart)}</Text>
          </Flex>
          <Flex width="100%" my={1} mb={4}>
            <Text></Text>
            <Box ml="auto" />
            <Text sx={{ fontStyle: "italic" }} fontSize={0}>
              Incl. VAT of {formatVatDeduction(cart)}
            </Text>
          </Flex>
          {cart.discounts.length === 0 && (
            <Flex
              flexDirection="column"
              width="100%"
              alignItems="center"
              mb={3}
            >
              <Text onClick={() => setShowPromo(true)}>
                {showPromo ? "Voucher" : "Apply voucher"}
              </Text>
              {showPromo && (
                <>
                  <StyledInput
                    ref={(ip) => (inputRef = ip)}
                    onChange={(e) => setCouponCode(e.target.value)}
                    value={couponCode}
                  />
                  <StyledButton onClick={() => addCouponCode(couponCode)}>
                    Apply
                  </StyledButton>
                </>
              )}
            </Flex>
          )}
          <Flex justifyContent="center">
            <StyledButton onClick={() => navigate("/checkout")}>
              Continue to checkout
            </StyledButton>
          </Flex>
        </ItemsContainer>
      ) : (
        <EmptyCart>Your bag is empty</EmptyCart>
      )}
    </CartDrawer>
  )
}

export default CartDrawerContainer
