import React from "react"
import { Flex } from "rebass"

import Navigation from "../navigation"
import Footer from "../footer"

import "@stripe/stripe-js"
import "./layout.css"

const Layout = ({ isCherishedObjects, isHomepage, children }) => {
  return (
    <Flex height="100%" flexDirection="column" overflowY="hidden">
      <Flex flexDirection="column" backgroundColor="#F4F0E5" height="100%">
        <Navigation
          isCherishedObjects={isCherishedObjects}
          isHomepage={isHomepage}
        />
        {children}
        <Footer />
      </Flex>
    </Flex>
  )
}

export default Layout
