import React, { useState, useEffect, useContext } from "react"
import styled from "@emotion/styled"
import { Flex, Text } from "rebass"
import Collapsible from "react-collapsible"
import { Input } from "@rebass/forms"
import NavigationCollapse from "../navigation/collapse-menu"
import useWindowDimensions from "../../utils/use-window-dimensions"
import { countryLookup } from "../../utils/countries"
import Medusa from "../../services/api"
import Spinner from "../ui/spinner"

const MenuDrawer = styled(Flex)`
  position: fixed;
  flex-direction: column;
  align-items: center;

  overflow-y: scroll;

  width: 100%;
  height: 100%;

  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 1rem;

  bottom: 0;
  right: 0;

  background-color: #eeebeb;

  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};

  transition: visibility 0.2s, opacity 0.2s linear;

  box-shadow: -19px 0 99px 0 rgba(0, 0, 0, 0.07),
    -10px 0 50px 0 rgba(0, 0, 0, 0.1);

  z-index: 1001;
  justify-content: space-between;
`

const NewsletterContainer = styled(Flex)`
  text-decoration: none;
  color: #00003a;
  font-family: Georgia;
  font-size: 12px;
  align-items: center;
`

const StyledInput = styled(Input)`
  border-bottom: 1px solid #00003a !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  font-size: 16px;

  border-radius: 0pt !important;

  width: 250px;

  &:focus {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline: none;
  }
`

const SignUpButton = styled(Text)`
  cursor: pointer;
`

const InfoContainer = styled(Flex)`
  flex-direction: column;

  font-size: 13px;
  font-family: Georgia;

  align-items: center;

  color: #00003a;

  width: 100%;

  > div {
    margin-bottom: 10px;
    cursor: pointer;
  }
`

const MenuDrawerContainer = ({
  setRegion,
  handleRegionOpen,
  cart,
  visible,
  hideMenu,
}) => {
  const { width } = useWindowDimensions()
  const [signingUp, setSigningUp] = useState(false)
  const [signedUp, setSignedUp] = useState(false)
  const [email, setEmail] = useState("")

  const handleNewsletterSignup = async () => {
    if (email) {
      setSigningUp(true)
      await Medusa.newsletter
        .signup({ email })
        .then(() => {
          setSignedUp(true)
          setSigningUp(false)
        })
        .catch(() => {
          setSignedUp(true)
          setSigningUp(false)
        })
    }
  }

  return (
    <MenuDrawer visible={visible}>
      <NavigationCollapse
        isHomepage={false}
        isMobile={true}
        hideMenu={hideMenu}
      />
      {width <= 768 && (
        <>
          <NewsletterContainer flexDirection="column" mt={5}>
            {signingUp ? (
              <Spinner />
            ) : !signedUp ? (
              <>
                <Text fontSize={width <= 768 ? "14px" : "16px"}>
                  NEWSLETTER
                </Text>
                <SignUpButton
                  fontSize={width <= 768 ? "14px" : "16px"}
                  onClick={() => handleNewsletterSignup()}
                >
                  SUBSCRIBE
                </SignUpButton>{" "}
                <StyledInput
                  onChange={({ currentTarget }) =>
                    setEmail(currentTarget.value)
                  }
                />
              </>
            ) : (
              <Text fontSize="16px">THANK YOU!</Text>
            )}
          </NewsletterContainer>
          <InfoContainer mt={5} mb={2}>
            {cart && cart.region && (
              <Text
                sx={{
                  textTransform: "uppercase",
                }}
                textAlign="center"
                onClick={() => handleRegionOpen(true)}
              >
                {countryLookup(cart.countryCode)}&thinsp;/&thinsp;
                {cart.currencyCode}
              </Text>
            )}
            <Text>LSJ STUDIO ©</Text>
            <Text onClick={() => handleInstagramClick()}>INSTAGRAM</Text>
            <Text>TERMS</Text>
          </InfoContainer>
        </>
      )}
    </MenuDrawer>
  )
}

export default MenuDrawerContainer
