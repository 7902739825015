import styled from "@emotion/styled"
import { navigate } from "gatsby"
import React, { useContext, useState } from "react"
import Collapsible from "react-collapsible"
import { Flex, Image, Text } from "rebass"
import MenuCross from "../../assets/images/cross.svg"
import LSJLogo from "../../assets/images/lsj-logo.svg"
import InterfaceContext from "../../context/interface-context"
import { countryLookup } from "../../utils/countries"

const StyledLink = styled(Text)`
  margin-bottom: ${(props) => (props.isOpen ? `5px` : `15px`)};
  margin-top: ${(props) => (props.isOpen ? `10px` : `0px`)};
  text-decoration: none;
  color: ${(props) => (props.isHomepage ? `#ffffff` : `#00003A`)};
  font-family: Georgia;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
`

const StyledText = styled(Text)`
  margin-bottom: ${(props) => (props.isOpen ? `5px` : `15px`)};
  margin-top: ${(props) => (props.isOpen ? `10px` : `0px`)};
  text-decoration: none;
  color: ${(props) => (props.isHomepage ? `#ffffff` : `#00003A`)};
  font-family: Georgia;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

  ${(props) => props.theme.bp.mobile} {
    font-size: 18px;
  }

  ${(props) => props.theme.bp.tablet} {
    font-size: 22px;
  }

  ${(props) => props.theme.bp.desktop} {
    font-size: 14px;
  }
`

const NavContainer = styled(Flex)`
  flex-direction: column;

  ${(props) => props.theme.bp.mobile} {
    margin-right: 0px !important;
  }

  ${(props) => props.theme.bp.tablet} {
    min-width: 100px !important;
    margin-right: 0px !important;
  }

  ${(props) => props.theme.bp.desktop} {
    min-width: 100px !important;
    margin-right: 35px !important;
  }

  .Collapsible {
    margin-bottom: 15px;
    width: 100%;

    ${(props) => props.theme.bp.mobile} {
      text-align: center;
    }

    ${(props) => props.theme.bp.tablet} {
      text-align: center;
    }

    ${(props) => props.theme.bp.desktop} {
      text-align: right;
    }
  }

  ${(props) => props.theme.bp.mobile} {
    ${StyledText} {
      text-align: center;
      width: 100%;
    }
  }

  ${(props) => props.theme.bp.tablet} {
    ${StyledText} {
      text-align: center;
      width: 100%;
    }
  }

  ${(props) => props.theme.bp.desktop} {
    ${StyledText} {
      text-align: right;
      width: 100%;
    }
  }
`

const CloseButton = styled(Image)`
  position: fixed;
  left: 20px;
  top: 20px;
  cursor: pointer;
`

const StyledRegion = styled(Text)`
  margin-bottom: ${(props) => (props.isOpen ? `5px` : `15px`)};
  margin-top: ${(props) => (props.isOpen ? `10px` : `0px`)};
  text-decoration: none;
  color: ${(props) => (props.isHomepage ? `#ffffff` : `#00003A`)};
  font-family: Georgia;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
`

const NavigationCollapse = ({
  isHomepage,
  isMobile,
  hideMenu,
  cart,
  handleRegionOpen,
}) => {
  const { showCart } = useContext(InterfaceContext)
  const [openMenu, setOpenMenu] = useState("")
  const [pickerOpen, setPickerOpen] = useState(false)
  const [settingRegion, setSettingRegion] = useState(false)

  const handleLinkClick = (path) => {
    hideMenu()
    navigate(`${path}`)
  }

  const handleSetRegion = async (regionId, countryCode) => {
    setSettingRegion(true)
    try {
      await setRegion(regionId, countryCode)
      setSettingRegion(false)
    } catch (erroe) {
      setSettingRegion(false)
    }
    setPickerOpen(false)
  }

  return (
    <NavContainer mt={4} isMobile={isMobile}>
      {isMobile && <CloseButton onClick={() => hideMenu()} src={MenuCross} />}
      {isMobile && <Image height="50px" src={LSJLogo} mb={5} />}
      <Collapsible
        id="SHOP"
        transitionTime={100}
        open={openMenu === "SHOP"}
        trigger={<StyledText isHomepage={isHomepage}>SHOP</StyledText>}
      >
        <Flex flexDirection="column">
          <StyledLink
            isHomepage={isHomepage}
            isOpen={true}
            onClick={() => handleLinkClick(`/all`)}
          >
            All
          </StyledLink>
          <StyledLink
            isHomepage={isHomepage}
            isOpen={true}
            onClick={() => handleLinkClick(`/lsj-bespoke`)}
          >
            LSJ Bespoke
          </StyledLink>
          {/* <StyledLink
            isHomepage={isHomepage}
            isOpen={true}
            onClick={() => handleLinkClick(`/madagascar-dresses`)}
          >
            Madagascar Dresses
          </StyledLink> */}
          <StyledLink
            isHomepage={isHomepage}
            isOpen={true}
            onClick={() => handleLinkClick(`/cherished-objects`)}
          >
            Cherished Objects
          </StyledLink>
          <StyledLink
            isHomepage={isHomepage}
            isOpen={true}
            onClick={() => handleLinkClick(`/blue-works`)}
          >
            Blue Works
          </StyledLink>
        </Flex>
      </Collapsible>
      <Collapsible
        id="ABOUT"
        open={openMenu === "ABOUT"}
        transitionTime={100}
        onClick={() => setOpenMenu(id)}
        trigger={<StyledText isHomepage={isHomepage}>ABOUT</StyledText>}
      >
        <Flex flexDirection="column">
          <StyledLink
            isHomepage={isHomepage}
            isOpen={true}
            onClick={() => handleLinkClick(`/about`)}
          >
            LSJ Studio
          </StyledLink>

          <StyledLink
            isHomepage={isHomepage}
            isOpen={true}
            onClick={() => handleLinkClick(`/giving-back`)}
          >
            Giving back
          </StyledLink>
        </Flex>
      </Collapsible>
      <StyledText
        id="BASKET"
        onClick={() => showCart()}
        isHomepage={isHomepage}
      >
        BASKET
      </StyledText>
      {cart && cart.region && (
        <Flex flexDirection="row" mt="10px">
          <StyledText
            mb={0}
            isHomepage={isHomepage}
            onClick={() => handleRegionOpen(true)}
          >
            {countryLookup(cart.countryCode)}&thinsp;/&thinsp;
            {cart.currencyCode}
          </StyledText>
        </Flex>
      )}
    </NavContainer>
  )
}

export default NavigationCollapse
