import React, { useContext, useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import styled from "@emotion/styled"
import Collapsible from "react-collapsible"
import { Box, Flex, Image, Text } from "rebass"
import CartDrawerContainer from "../cart-drawer"
import useWindowDimensions from "../../utils/use-window-dimensions"
import LSJLogo from "../../assets/images/lsj-logo.svg"
import WhiteLSJLogo from "../../assets/images/white-lsj-logo.svg"
import COLogo from "../../assets/images/cherished-objects-logo.svg"
import StoreContext from "../../context/store-context"
import InterfaceContext from "../../context/interface-context"
import HamburgerMenu from "../../assets/images/hamburger-menu.svg"
import WhiteHamburgerMenu from "../../assets/images/white-hamburger.svg"
import NavigationCollapse from "./collapse-menu"
import MenuDrawerContainer from "../menu-drawer"
import RegionDrawerContainer from "../region-drawer"

const NavigationContainer = styled(Flex)`
  z-index: 1000;
  position: absolute;
  width: 100vw;
  flex-direction: row;
  align-items: none;

  ${(props) => props.theme.bp.mobile} {
  }
`

const StyledImage = styled(Image)`
  max-height: ${(props) => (props.isCherishedObjects ? "125px" : "75px")};

  margin-top: 40px !important;
  margin-left: 40px !important;

  ${(props) => props.theme.bp.mobile} {
    margin-top: 20px !important;
    margin-left: 20px !important;
    ${(props) =>
      props.isCherishedObjects &&
      `
      height: 75px;
    `}
  }

  cursor: pointer;
`

const MenuContainer = styled(Flex)`
  margin-right: 20px !important;
  ${(props) => props.theme.bp.tablet} {
    margin-right: 20px;
  }
  ${(props) => props.theme.bp.mobile} {
    margin-right: 20px !important;
    margin-top: 20px !important;
  }
`

const Navigation = ({ isCherishedObjects, isHomepage }) => {
  const {
    showCart,
    hideCart,
    toggleCart,
    isCartOpen,
    isMenuOpen,
    showMenu,
    hideMenu,
  } = useContext(InterfaceContext)
  const {
    cart,
    updateLineItem,
    removeLineItem,
    setRegion,
    regions,
    addCouponCode,
    removeCouponCode,
  } = useContext(StoreContext)

  const [showRegions, setShowRegions] = useState(false)
  const [initNav, setInitNav] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  // const { width } = useWindowDimensions()

  const handleLogoClick = () => {
    navigate(`/`)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window.innerWidth <= 768) {
        setIsMobile(true)
        setInitNav(true)
      } else {
        setIsMobile(false)
        setInitNav(true)
      }

      if (window.location.search.includes("region_pick=true")) {
        setShowRegions(true)
      }
    }
  }, [])

  return (
    <NavigationContainer>
      {isCherishedObjects ? (
        <StyledImage
          src={COLogo}
          onClick={() => handleLogoClick()}
          isCherishedObjects={true}
        />
      ) : (
        <StyledImage
          src={isHomepage ? WhiteLSJLogo : LSJLogo}
          onClick={() => handleLogoClick()}
          isCherishedObjects={isCherishedObjects}
        />
      )}
      <Box ml="auto" />
      {initNav &&
        (isMobile ? (
          <MenuContainer>
            <Image
              height="30px"
              marginTop="20px"
              src={isHomepage ? WhiteHamburgerMenu : HamburgerMenu}
              onClick={() => showMenu()}
            />
          </MenuContainer>
        ) : (
          <NavigationCollapse
            isHomepage={isHomepage}
            hideMenu={hideMenu}
            cart={cart}
            regions={regions}
            handleRegionOpen={() => setShowRegions(true)}
            setRegion={setRegion}
          />
        ))}
      <MenuDrawerContainer
        cart={cart}
        regions={regions}
        handleRegionOpen={() => setShowRegions(true)}
        setRegion={setRegion}
        visible={isMenuOpen}
        closeMenu={hideMenu}
        hideMenu={hideMenu}
      />
      <CartDrawerContainer
        cart={cart}
        onRemoveLine={removeLineItem}
        onQuantityUpdate={updateLineItem}
        visible={isCartOpen}
        setVisibility={hideCart}
        addCouponCode={addCouponCode}
        removeCouponCode={removeCouponCode}
      />
      {showRegions && (
        <RegionDrawerContainer
          onClose={() => setShowRegions(false)}
          onSetCountry={(c) => {
            setRegion(c.region_id, c.country_code)
            setShowRegions(false)
          }}
        />
      )}
    </NavigationContainer>
  )
}

export default Navigation
