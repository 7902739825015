import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Flex, Image } from "rebass"

import MenuCross from "../../assets/images/cross.svg"
import Medusa from "../../services/api"
import { countryLookup } from "../../utils/countries"

const RegionContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;

  font-family: Georgia;

  ${(props) => props.theme.bp.desktop} {
    width: 50%;
  }

  ${(props) => props.theme.bp.largeDesktop} {
    width: 40%;
  }

  height: 100%;
  z-index: 1001;
  padding: 10px;
  background-color: #eeebeb;
`

const StyledHeader = styled(Flex)`
  display: flex;
  ${(props) => props.theme.bp.desktop} {
    display: none;
  }

  .header {
    font-weight: normal;
  }
`

const SearchContainer = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.lightest};
  border: 1px solid ${(props) => props.theme.colors.darkest};

  .bar-icon {
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.colors.darkest};
    flex-direction: row;
    padding: 10px;
    padding-left: 4%;

    .icon {
      align-self: center;
    }
  }

  ${(props) => props.theme.bp.tablet} {
    border: none;
    background-color: ${(props) => props.theme.colors.almostWhite};
    height: 90%;

    .bar-icon {
      padding-left: 35px;
    }
  }
`

const Countries = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 10%;
  margin: 12px 0;
  max-height: 100%;
  overflow: scroll;

  ${(props) => props.theme.bp.tablet} {
    padding-left: 60px;
  }
`

const Country = styled.div`
  line-height: 1.25;
  margin-bottom: 14px;

  cursor: pointer;
`

const Info = styled.div`
  display: none;

  ${(props) => props.theme.bp.tablet} {
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
    display: block;
    padding-left: 60px;
  }
`

const MobileOnly = styled.div``

const Header = styled.div``
const SearchIcon = styled.div``
const CloseIcon = styled.div``
const SearchBar = styled.input`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 3%;
  aling-self: center;

  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  ${(props) => props.theme.bp.tablet} {
    padding-left: 14px;
  }
`

const RegionModal = ({ visible, onSetCountry, onClose }) => {
  // const [visited, setVisited] = useState(false)
  const [countries, setCountries] = useState(() => {
    const defaultCountries = {
      allCountries: [],
      visibleCountries: [],
    }

    return defaultCountries
  })

  const searchRef = useRef()
  const containerRef = useRef()

  const [searchValue, setSearchValue] = useState("")

  useEffect(() => {
    Medusa.regions
      .list()
      .then(({ data }) => {
        let countries = data.regions.map((region) => {
          return region.countries.map((country) => {
            const countryDisplayName = countryLookup(
              country.iso_2.toUpperCase()
            )

            return {
              name: countryDisplayName,
              country_code: country.iso_2.toUpperCase(),
              currency_code: region.currency_code,
              region: region.name,
              region_id: region.id,
            }
          })
        })

        return countries.flat().sort((a, b) => sortByName(a, b))
      })
      .then((countries) => {
        setCountries({ allCountries: countries, visibleCountries: countries })
      })
  }, [])

  useEffect(() => {
    if (visible) {
      searchRef.current.focus()
    }
  }, [visible])

  const handleClick = (e) => {
    if (!containerRef.current) {
      return
    }

    const visited = containerRef.current.className.includes("visited")

    if (!containerRef.current.contains(e.target)) {
      if (visited) {
        onClose()
      }
    } else if (!visited) {
      containerRef.current.className += " visited"
    }
  }

  useEffect(() => {
    // add when mounted
    window.addEventListener("mouseover", handleClick, true)
    // return function to be called when unmounted
    return () => {
      window.removeEventListener("mouseover", handleClick, true)
    }
  }, [])

  const sortByName = (a, b) => {
    if (a.name > b.name) {
      return 1
    } else if (b.name > a.name) {
      return -1
    } else {
      return 0
    }
  }
  const handleSearch = (e) => {
    let searchValue = e.target.value
    setSearchValue(searchValue)

    let visible = countries.allCountries.filter((country) => {
      let countryName = country.name.toLowerCase()
      return countryName.startsWith(searchValue.toLowerCase())
    })

    setCountries({ ...countries, visibleCountries: visible })
  }

  return (
    <RegionContainer ref={containerRef} visible={visible}>
      <StyledHeader p={10} justifyContent="center">
        <Image
          sx={{
            position: "fixed",
            top: "20px",
            left: "20px",
            cursor: "pointer",
          }}
          onClick={onClose}
          src={MenuCross}
        />
        <h2 className={"header"}>Change Country</h2>
      </StyledHeader>
      <SearchContainer visible={searchValue === ""}>
        <div className="bar-icon">
          <SearchIcon className="icon" />
          <SearchBar
            ref={searchRef}
            type="text"
            name="search"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearch}
            visible={searchValue === ""}
          />
        </div>
        <Countries>
          {countries.allCountries &&
            countries.visibleCountries.map((country) => {
              return (
                <Country
                  key={country.name}
                  onClick={() => onSetCountry(country)}
                >
                  {country.name}
                </Country>
              )
            })}
        </Countries>
      </SearchContainer>
      <Info>
        Choose your shipping location in order to see correct pricing, delivery
        times & shipping costs.
      </Info>
    </RegionContainer>
  )
}

export default RegionModal
